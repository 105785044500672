<template>
  <div id="payment" class="wd-100 fx-main pd-0">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div class="container" v-if="order">
      <div class="payment-success-block fx-main wd-100 success-bg txt-align-center">
        <div class="cart-checkout-options wd-100 fx fx-direct-cont">
          <div class="txt-align-center">
            <span class="text-payment">Pedido efetuado com sucesso</span>
          </div>
        </div>

        <div class="wd-100">
          <h2 v-if="post_order_subtext">{{ post_order_subtext }}</h2>
        </div>
        <div class="wd-100 mg-top-1">
          <i class="far fa-check-circle"></i>
        </div>

        <div class="wd-100" v-if="order && order.payment_method === 'boleto'">
          <p>
            Pague
            {{
              (order.full_amount - amountDiscount(order)) | toCurrency
            }}
            através do Boleto abaixo para receber seus produtos.
          </p>
          <!-- <div v-if="order.coupons.length == 0">
            <p>Pague {{ order.full_amount - (order.items_amount * order.discount_percentage) | toCurrency }} através do Boleto abaixo para receber seus produtos.</p>
          </div>  
          <div v-else>
            <p>Pague {{ order.full_amount - ( order.items_amount / (1 - (order.coupons[0].amount/100)) * order.discount_percentage)| toCurrency }} através do Boleto abaixo para receber seus produtos</p>
          </div>   -->
        </div>

        <div class="wd-100" v-if="order && order.payment_method === 'credit_card'"
        >
          <p>Seu pedido está sendo processado...</p>
        </div>

        <div class="wd-100 fx fx-center">
          <div>
            <div>
              Status:
              <span class="main-title">{{ order_status | toStatus }}</span>
            </div>

            <template v-if="order.payment_directions">
              <strong v-if="order.payment_directions.barcode">
                Código de Barras:
                <span>{{ order.payment_directions.barcode }}</span>
              </strong>
              <br />
              <strong v-if="order.payment_directions.expiration_date">
                Vencimento:
                <span>{{
                  order.payment_directions.expiration_date | formatDate
                }}</span>
              </strong>
            </template>

            <div class="wd-100 fx-center fx-direct-cont mg-1-top">
              <div class="fx fx-center" v-if="order && order.payment_method === 'boleto'">
                <a
                  class="btn clickable"
                  :disabled="order.payment_link ? false : 'DISABLED'"
                  :href="order.payment_link"
                  target="_blank"
                  >Ver Boleto</a
                >
              </div>
              <div class="fx fx-center" v-if="order && order.payment_method === 'pix'">
                <div class="mt-3 mb-3">
                  <span> Escaneie esse código para pagar </span>
                <div class="mt-3">
                  <qrcode :background="background" :size="size" :cls="qrCls" :value="order.pix_qr_code"></qrcode>
                </div>
                <div>
                  <textarea 
                    class="textarea" 
                    v-model="order.pix_qr_code"
                    ref="copiar"> 
                  </textarea>
                </div>
                <div class="fx fx-center mt-3">
                  <span> Ou copie esse código QR para fazer o pagamento__</span>
                  <span @click="copy" class="fa fa-copy" title="Copiar"> __{{ this.msg_pix }} </span>
                </div>
                </div>
              </div>
              <div class="fx fx-center mg-1-top">
                <router-link
                  v-if="order"
                  class="btn clickable"
                  :to="{
                    name: 'order',
                    params: { order_id: order.id, order: order },
                  }"
                  >Ver Pedido</router-link
                >
              </div>
            </div>
          </div>
          <div
            class="fixed-buttons fixed container fx fx-end mg-1-top"
            v-if="order && order.payment_method === 'boleto'"
          >
            <a
              class="p-detail-btn clickable"
              :disabled="order.payment_link ? false : 'DISABLED'"
              :href="order.payment_link"
              target="_blank"
              >Ver Boleto</a
            >
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Order from "@/services/order.js";
import { setTimeout, clearTimeout } from "timers";
import Loading from "vue-loading-overlay";
import router from "@/router";

import { buildImgUrl } from "@/services/item";

import Qrcode from 'v-qrcode/src/index';

export default {
  name: "Payment",
  template: "#payment",
  components: {
    Loading,
    Qrcode,
  },
  data: function () {
    return {
      order: {},
      timeout: null,
      isLoading: false,
      post_order_subtext: process.env.VUE_APP_POST_ORDER_SUBTEXT || "",
      msg_pix: null,
      qrCls: 'qrcode',
      size: 220,
      background: '#eeeeee',
    };
  },
  props: {
    order_id: String,
    order_object: Object,
  },
  computed: {
    orderItemsTotalPrice: function () {
      if (!this.order || !this.order.items) {
        return 0;
      }
      return this.order.items
        .map(
          (i) =>
            Number((i.variation && i.variation.price) || i.product.price) *
            i.quantity
        )
        .reduce((a, b) => a + b, 0);
    },
    orderShippingPrice: function () {
      if (!this.order || !this.order.supplier_entries) {
        return 0;
      }
      return this.order.supplier_entries
        .map((i) => Number(i.shipping_cost))
        .reduce((a, b) => a + b, 0);
    },
    order_status: function () {
      if (!this.order || !this.order.status) {
        return null;
      }
      return this.order.status;
    },
  },
  mounted() {
    this.isLoading = true;

    if (this.order_object) {
      this.order = this.order_object;
      this.order_id = this.order.id;
      this.checkLinkPull();
    } else {
      Order.get(this.order_id).then((order) => {
        this.order = order;
        this.checkLinkPull();
      });
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
     copy: function() {
        var copyTextarea = this.$refs.copiar
        copyTextarea.select();
        try {
          var successful = document.execCommand('copy');
          this.msg_pix = "copiado!";
        } catch (err) {
           this.msg_pix = "não foi copiado!";
        }
      },
    amountDiscount(order) {
      if (!order.enabled_purchase_discount) {
        return 0;
      }
      if (order.items_amount < order.purchase_amount) {
        return 0;
      }
      let newFullAmount = order.items_amount;
      if (order.coupons.length != 0) {
        if (order.coupons[0].modifier_type === "PERCENTAGE") {
          newFullAmount =
            order.items_amount / (1 - order.coupons[0].amount / 100);
        } else {
          newFullAmount = order.items_amount + order.coupons[0].amount;
        }
      }
      return newFullAmount * order.discount_percentage + 0.005;
    },
    buildImgUrl: function (item) {
      return buildImgUrl(item);
    },
    checkLinkPull: function () {
      if (
        this.order &&
        (this.order.payment_link || this.order.payment_method !== "boleto") &&
        this.order.status === "AWAITING_PAYMENT"
      ) {
        this.isLoading = false;
        return;
      }
      this.timeout = setTimeout(() => {
        Order.get(this.order_id).then((order) => {
          this.order = order;
          switch (order.status) {
            case "PAID":
              router.push({
                name: "thank-you",
                params: {
                  order_id: order.id,
                  order_object: order,
                },
              });
              break;
            case "DENIED":
            case "REFUSED":
            case "CANCELLED":
              this.$swal({
                title: "Pagamento não autorizado",
                text: "Seu pagamento não foi autorizado! Confirme os dados e tente novamente!",
                icon: "error",
              }).then(() => {
                router.push({
                  name: "order-data",
                });
              });
              this.isLoading = false;
              break;
            default:
              this.checkLinkPull();
          }
        });
      }, 1000);
    },
  },
};
</script>